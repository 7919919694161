import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CodeIsSportGallery = () => (
  <Layout>
    <SEO
      title="Code Championship Gallery | Promotional Material for Code Championship"
      description="Marketing and promotional material - videos and images - for Code Championship."
      keywords={['marketing', 'promotional', 'images', 'videos', 'gallery', 'code is sport']}
    />
    <h1>Code Is Sport Gallery</h1>
    <div className="stripe-hr thin" />
    <h2>Our Gallery has Moved</h2>
    <Link to="/gallery">
      Visit our new gallery.
    </Link>
  </Layout>
);

export default CodeIsSportGallery;
